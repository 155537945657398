import React from 'react'

import { List, ListItem, Paragraph, SkipLink } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import ButtonHTMLExample from '../../examples/html/ButtonHTMLExample'

export const SkipLinkHTMLExample = () => (
  <ButtonHTMLExample
    as="a"
    className="skip-link"
    href="#content"
    variant="outline"
  >
    Hyppää sisältöön
  </ButtonHTMLExample>
)

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="SkipLink"
    components={[{ component: SkipLink, restElement: 'Button' }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          <Code>SkipLink</Code> tulee näkyviin, kun se saa fokuksen
          näppäimistöllä navigoitaessa. Se sijoitetaan DOM:in ensimmäiseksi
          linkiksi ja linkitetään haluttuun sisältöön.
        </ListItem>
        <ListItem>
          <Code>SkipLink</Code> ohjaa oletuksena <Code>#content</Code> kohtaan,
          jonka vastapari löytyy <Code>Main</Code>-komponentista.
        </ListItem>
      </List>
      <Playground>
        <SkipLink />
      </Playground>
      <Playground format="html">
        <SkipLinkHTMLExample />
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Kaikki sivuston sisältö tulee sijaita maamerkkien (landmarks) sisällä.
        Tärkeimmät maamerkit ovat semanttiset html-elementit <Code>header</Code>
        , <Code>main</Code> ja <Code>footer</Code>. SkipLinkin tulee sijaita
        sivun alussa <Code>header</Code> -elementin sisällä, ensimmäisenä
        elementtinä.
      </Paragraph>
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Varmista, että komponentti on linkitetty haluttuun sisältöön.
        </ListItem>
        <ListItem>
          Jokainen navigaatioelementti pitää pystyä ohittamaan.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
