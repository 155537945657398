import React from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  Image,
  Link,
  Main,
  Wrapper
} from '@te-digi/styleguide'

import { Example as TermExample } from '../../../pages/verkkopalvelu/term'
import { LinkMock } from '../../../components/LinkMock'
import { SkipLinkHTMLExample } from '../../../pages/navigointi/skiplink'
import { SomeButtonHTMLExample2 } from '../../../pages/navigointi/somebutton'
import placeholderImage from '../../../assets/placeholder-2.jpg'

import { HeaderHTMLExample } from '../HeaderHTMLExample'
import { FooterHTMLExample } from '../FooterHTMLExample'
import { SideNavHTMLExample } from '../SideNavHTMLExample'

const ContentPageExample = () => (
  <>
    {SkipLinkHTMLExample()}
    <Wrapper>
      {HeaderHTMLExample({ activeSecondaryItem: 'Työnhaku' })}
      <div className="columns columns-sidebar">
        <div>
          <SideNavHTMLExample />
        </div>
        <Main>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link as={LinkMock}>Etusivu</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link as={LinkMock}>Henkilöasiakkaat</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link as={LinkMock}>Työnhaku</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link as={LinkMock}>Ilmoittaudu työnhakija-asiakkaaksi</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Sit amet</BreadcrumbItem>
          </Breadcrumb>
          <h1 className="h1">Sit amet</h1>
          <Image
            alt="Lorem ipsum dolor sit amet, consectetuer adipiscing elit"
            fluid
            src={placeholderImage}
          />
          <div className="typography">
            <h2 className="h3">Lorem ipsum dolor sit amet</h2>
            <p className="lead">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
              posuere interdum sem. Quisque ligula eros ullamcorper quis,
              lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu.
              Sed arcu lectus auctor vitae, consectetuer et venenatis eget
              velit. Sed augue orci, lacinia eu tincidunt et eleifend nec lacus.
            </p>
            <p>
              Nam molestie nec tortor. Donec placerat leo sit amet velit.
              Vestibulum id justo ut vitae massa. Proin in dolor mauris
              consequat aliquam. Donec ipsum, vestibulum ullamcorper venenatis
              augue.
            </p>
            <p>
              Donec ultricies nisl ut felis, suspendisse potenti. Lorem ipsum
              ligula ut hendrerit mollis, ipsum erat vehicula risus, eu suscipit
              sem libero nec erat. Aliquam erat volutpat. Sed congue augue vitae
              neque. Nulla consectetuer porttitor pede. Fusce purus morbi tortor
              magna condimentum vel, placerat id blandit sit amet tortor.
            </p>
            <h2 className="h3">Mauris sed libero</h2>
            <TermExample />
            <ul>
              <li>
                Vivamus placerat lacus vel vehicula scelerisque, dui enim
                adipiscing lacus sit amet sagittis, libero enim vitae mi
              </li>
              <li>In neque magna posuere, euismod ac tincidunt tempor est</li>
              <li>Ut suscipit nisi eu purus</li>
            </ul>
            <p>
              Nam molestie nec tortor. Donec placerat leo sit amet velit.
              Vestibulum id justo ut vitae massa. Proin in dolor mauris
              consequat aliquam. Donec ipsum, vestibulum ullamcorper venenatis
              augue. Aliquam tempus nisi in auctor vulputate, erat felis
              pellentesque augue nec, pellentesque lectus justo nec erat.
              Aliquam et nisl. Quisque sit amet dolor in justo pretium
              condimentum.
            </p>
            <h3 className="h4">Quisque ligula eros ullamcorper quis</h3>
            <p>
              Suspendisse facilisis nulla in lacinia laoreet, lorem velit
              accumsan velit vel mattis libero nisl et sem. Proin interdum
              maecenas massa turpis sagittis in, interdum non lobortis vitae
              massa. Quisque purus lectus, posuere eget imperdiet nec sodales id
              arcu. Vestibulum elit pede dictum eu, viverra non tincidunt eu
              ligula.
            </p>
          </div>
          {SomeButtonHTMLExample2()}
        </Main>
      </div>
      <FooterHTMLExample />
    </Wrapper>
  </>
)

export { ContentPageExample }
