import React from 'react'

import { Head } from '../../components/Head'
import { ContentPageExample } from '../../examples/html/pages/ContentPageExample'

const Page = () => (
  <>
    <Head title="Sisältösivu" />
    <ContentPageExample />
  </>
)

export default Page
